.header {
  display: flex;
  flex-direction: column;
  position: relative;

  @media print {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    flex: 1;

    &:after {
      content: '';
      background: repeating-linear-gradient(90deg, $accent, $accent 2px, transparent 0, transparent 10px);
      display: block;
      width: 100%;
      right: 10px;
    }

    a {
      flex: 0 0 auto;
      max-width: 100%;
      text-decoration: none;
    }
  }
}
